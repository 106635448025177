// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query DossierList(
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
    $blockId: ID,
    $context: String,
    $page: Int,
    $first: Int,
    $sorters: [DataGridSorterInput],
    $filters: [DataGridFilterInput])
  {
    list: dossierListBlockById(
      id: $dossierId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
      blockId: $blockId,
      context: $context,
      page: $page,
      first: $first,
      sorters: $sorters,
      filters: $filters
    ) {
      data {
        data
      }
      dataGridInfo {
        columns {
          name
          label
        }
        filters {
          name
          applied
          data
          metadata
          __typename
        }
        sorters {
          name
          applied
          direction
        }
        metadata
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export default QUERY;
